.addon-font {
  font-family: BlinkMacSystemFont,-apple-system,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,Helvetica,Arial,sans-serif;
}

.has-text-white {
  color: #fff !important;
}

.has-background-primary-alt {
  background-color: #01a3a4 !important;
}

.section-header {
  margin: 2rem 0 5rem;
}

.header-subtitle {
  color: #4a4a4a;
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.25;
}

article, aside, figure, footer, header, hgroup, section {
  display: block;
}
